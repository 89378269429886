import { oneLine } from 'common-tags';

import { Add, Less } from '@/icons';

import type { FunctionComponent } from 'react';
import type { TypeNumberProps } from './types';

/**
 * Number
 */
export const Number: FunctionComponent<TypeNumberProps> = ({
  className = '',
  classNames,
  variant = 'normal',
  disabled,
  onSubtract,
  onAdd,
  children,
  value,
  addDisabled,
  subtractDisabled,
}: TypeNumberProps) => (
  <div
    className={oneLine`inline-flex max-h-10 flex-row items-center justify-between rounded-full border aria-disabled:pointer-events-none aria-disabled:cursor-auto aria-disabled:opacity-50 ${
      variant === 'normal'
        ? 'gap-1 px-2 py-0.5 sm:gap-2'
        : 'gap-4 px-3 py-2 sm:gap-6'
    } ${className} `}
    aria-disabled={disabled}
  >
    <Less
      width={variant === 'normal' ? 16 : 24}
      height={variant === 'normal' ? 16 : 24}
      data-playwright="cartLine-less"
      className={oneLine`flex-shrink-0 cursor-pointer aria-disabled:pointer-events-none aria-disabled:cursor-auto aria-disabled:opacity-50 hover:text-primary-600 ${classNames?.subtract ?? ''} `}
      onClick={() => (!disabled || !subtractDisabled) && onSubtract()}
      aria-disabled={subtractDisabled}
    />

    {children}

    <span className={`w-5 select-none text-center ${classNames?.value ?? ''}`}>
      {value}
    </span>

    <Add
      width={variant === 'normal' ? 16 : 24}
      height={variant === 'normal' ? 16 : 24}
      data-playwright="cartLine-add"
      className={oneLine`flex-shrink-0 cursor-pointer aria-disabled:pointer-events-none aria-disabled:cursor-auto aria-disabled:opacity-50 hover:text-primary-600 ${classNames?.add ?? ''} `}
      onClick={() => (!disabled || !addDisabled) && onAdd()}
      aria-disabled={addDisabled}
    />
  </div>
);

Number.displayName = 'Number';
