import { useEffect } from 'react';

/**
 * Hook to fix an element that has full height and width. Without
 * this hook in Safari you can make scroll in the page.
 * @param  {boolean} applyFix To conditional render the fix
 */
export const useFixSafariPortal = (applyFix = true) => {
  useEffect(() => {
    if (applyFix) {
      const body = window.document.getElementById('body-portal');

      if (body?.style) {
        body.style.position = 'fixed';
        body.style.inset = '0';
      }
    }

    return () => {
      const body = window.document.getElementById('body-portal');

      if (body?.style) {
        body.style.position = '';
        body.style.inset = '';
      }
    };
  }, [applyFix]);
};
