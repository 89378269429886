import { SkeletonTheme } from 'react-loading-skeleton';

import type { CustomSkeletonThemeProps } from './types';

/**
 * CustomSkeletonTheme
 */
export const CustomSkeletonTheme = ({
  children,
  baseColor = '#F8F7F4',
  highlightColor = '#F4F0EB',
}: CustomSkeletonThemeProps) => (
  <SkeletonTheme
    baseColor={baseColor}
    highlightColor={highlightColor}
    inline
    borderRadius={0}
  >
    {children}
  </SkeletonTheme>
);

CustomSkeletonTheme.displayName = 'CustomSkeletonTheme';
