import Skeleton from 'react-loading-skeleton';
import { oneLine } from 'common-tags';

import { CustomSkeletonTheme } from '@/atoms';

import { priceToNumber, toCurrency } from '@/lib/utils';

import type { FunctionComponent } from 'react';
import type { TypePriceProps } from './types';

/**
 * Price
 */
export const Price: FunctionComponent<TypePriceProps> = ({
  className = '',
  isDescription = false,
  regularPrice,
  price,
  quantity,
  loading,
  variant = 'vertical',
}: TypePriceProps) => {
  const hasDiscount =
    price && regularPrice && toCurrency(price) !== toCurrency(regularPrice);
  return (
    <CustomSkeletonTheme>
      <div
        className={oneLine`inline-flex text-right ${
          variant === 'horizontal' ? 'flex-row-reverse gap-2' : 'flex-col'
        } ${className} `}
        data-playwright="price"
      >
        {hasDiscount && regularPrice && (
          <p
            className={oneLine`u-body grid items-center tracking-normal text-primary-400 line-through ${
              isDescription ? 'u-body--s' : 'u-body--xs'
            } `}
          >
            {toCurrency(regularPrice)}
          </p>
        )}
        <p
          className={oneLine`font-bold ${
            isDescription ? 'u-headline text-4xl' : 'u-body u-body--m'
          } ${hasDiscount ? 'text-secondary-500' : 'text-primary-900'} `}
        >
          {loading || !price ? (
            <Skeleton containerClassName="min-w-10 block" />
          ) : (
            toCurrency(price)
          )}
        </p>
        {quantity && price && quantity !== 0 && quantity !== 1 && (
          <p className="u-font-card u-font-card--subtitle text-typo-secondary">
            Unidad: {toCurrency(priceToNumber(price) / quantity)}
          </p>
        )}
      </div>
    </CustomSkeletonTheme>
  );
};
